var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-gapless pt-5 pb-5"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box backround"},[_c('h2',{staticClass:"is-size-2 welcome-text"},[_vm._v(" Welcome, "),_c('br'),_vm._v(" "+_vm._s(_vm.currentUser.user.role)+" ")])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box",staticStyle:{"min-height":"300px"}},[(!_vm.isLoading)?_c('div',{attrs:{"id":""}},[_c('h2',{staticClass:"is-size-2"},[_vm._v("Select Your Apps")]),_c('div',{staticClass:"columns is-multiline select-apps"},_vm._l((_vm.companyServices),function(companyService,key){return _c('div',{key:key,staticClass:"column is-12"},[(_vm.checkCompanyServiceUserService(companyService.serviceId))?_c('a',{attrs:{"href":companyService.service.domain,"target":"_blank"}},[(companyService.service.name === 'RISE')?_c('div',{staticClass:"box"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"columns"},[_vm._m(0,true),_c('div',{staticClass:"column product-title is-size-3"},[_vm._v(" "+_vm._s(companyService.service.name)+" ")])])])]):_vm._e()]):_vm._e()])}),0),_c('div',{staticStyle:{"display":"grid"}},[(
              _vm.currentUser &&
              _vm.currentUser.user &&
              (_vm.currentUser.user.role == 'Owner' ||
                _vm.currentUser.user.role == 'staff')
            )?_c('b-button',{attrs:{"tag":"router-link","to":"/","type":"is-success","icon-right":"arrow-right"}},[_vm._v(" Space ID Dashboard ")]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-button',{staticClass:"mt-4",attrs:{"type":"is-info is-medium","icon-right":"pencil"},on:{"click":_vm.updatePasswordModal}},[_vm._v(" Update Account Password ")])],1)],1),_c('b-modal',{attrs:{"width":320,"destroy-on-hide":true,"can-cancel":['escape']},model:{value:(_vm.isComponentModalActive),callback:function ($$v) {_vm.isComponentModalActive=$$v},expression:"isComponentModalActive"}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Update Account Password")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){_vm.isComponentModalActive = false}}})]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"name":"Old Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Old Password"}},[_c('b-input',{attrs:{"placeholder":"Old Password","type":"password","password-reveal":""},model:{value:(_vm.password.oldPassword),callback:function ($$v) {_vm.$set(_vm.password, "oldPassword", $$v)},expression:"password.oldPassword"}})],1),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,4025947935)}),_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"New Password"}},[_c('b-input',{attrs:{"type":"password","placeholder":"New Password","password-reveal":""},model:{value:(_vm.password.newPassword),callback:function ($$v) {_vm.$set(_vm.password, "newPassword", $$v)},expression:"password.newPassword"}})],1),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3209958180)}),_c('ValidationProvider',{attrs:{"name":"Confirm New Password","rules":"required|confirmed:password.newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Confirm New Password"}},[_c('b-input',{attrs:{"type":"password","placeholder":"Re Type New Password","password-reveal":""}})],1),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3620716403)})],1),_c('footer',{staticClass:"modal-card-foot",staticStyle:{"display":"flex","justify-content":"end"}},[_c('b-button',{attrs:{"type":"is-info","disabled":_vm.buttonDisabled},on:{"click":function($event){return _vm.updatePassword()}}},[_vm._v(" Update Password ")])],1)])])],1):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-one-fifth"},[_c('img',{staticClass:"product-image",attrs:{"src":"/images/rise.png","alt":"RISE"}})])}]

export { render, staticRenderFns }