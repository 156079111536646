<template>
  <div class="columns is-gapless pt-5 pb-5">
    <div class="column">
      <div class="box backround">
        <h2 class="is-size-2 welcome-text">
          Welcome,
          <br />
          {{ currentUser.user.role }}
        </h2>
      </div>
    </div>
    <div class="column">
      <div class="box" style="min-height: 300px">
        <div class="" id="" v-if="!isLoading">
          <h2 class="is-size-2">Select Your Apps</h2>
          <div class="columns is-multiline select-apps">
            <div
              class="column is-12"
              v-for="(companyService, key) in companyServices"
              :key="key"
            >
              <a
                :href="companyService.service.domain"
                target="_blank"
                v-if="checkCompanyServiceUserService(companyService.serviceId)"
              >
                <div class="box" v-if="companyService.service.name === 'RISE'">
                  <div class="field">
                    <div class="columns">
                      <div class="column is-one-fifth">
                        <img
                          src="/images/rise.png"
                          alt="RISE"
                          class="product-image"
                        />
                      </div>
                      <div class="column product-title is-size-3">
                        {{ companyService.service.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div style="display: grid">
            <b-button
              tag="router-link"
              to="/"
              type="is-success"
              icon-right="arrow-right"
              v-if="
                currentUser &&
                currentUser.user &&
                (currentUser.user.role == 'Owner' ||
                  currentUser.user.role == 'staff')
              "
            >
              Space ID Dashboard
            </b-button>
            <div style="display: flex; justify-content: center">
              <b-button
                type="is-info is-medium"
                class="mt-4"
                icon-right="pencil"
                @click="updatePasswordModal"
              >
                Update Account Password
              </b-button>
            </div>
          </div>
          <b-modal
            v-model="isComponentModalActive"
            :width="320"
            :destroy-on-hide="true"
            :can-cancel="['escape']"
          >
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">Update Account Password</p>
                <button
                  type="button"
                  class="delete"
                  @click="isComponentModalActive = false"
                />
              </header>
              <section class="modal-card-body">
                <ValidationProvider
                  name="Old Password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field label="Old Password">
                    <b-input
                      placeholder="Old Password"
                      type="password"
                      v-model="password.oldPassword"
                      password-reveal
                    ></b-input>
                  </b-field>
                  <p class="error-message">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>

                <ValidationProvider
                  name="New Password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field label="New Password">
                    <b-input
                      type="password"
                      placeholder="New Password"
                      v-model="password.newPassword"
                      password-reveal
                    ></b-input>
                  </b-field>
                  <p class="error-message">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>

                <ValidationProvider
                  name="Confirm New Password"
                  rules="required|confirmed:password.newPassword"
                  v-slot="{ errors }"
                >
                  <b-field label="Confirm New Password">
                    <b-input
                      type="password"
                      placeholder="Re Type New Password"
                      password-reveal
                    ></b-input>
                  </b-field>
                  <p class="error-message">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </section>
              <footer
                class="modal-card-foot"
                style="display: flex; justify-content: end"
              >
                <b-button
                  type="is-info"
                  :disabled="buttonDisabled"
                  @click="updatePassword()"
                >
                  Update Password
                </b-button>
              </footer>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    selectdata: {
      isSelect: Boolean,
    },
  },
  data() {
    return {
      userServices: {},
      isLoading: false,
      companyServices: true,
      buttonDisabled: false,
      isComponentModalActive: false,
      password: {
        oldPassword: null,
        newPassword: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    await this.$store.dispatch('auth/getCurrentUser')
    this.isLoading = true
    this.getCurrentUserService()
    this.checkCompanyService()
  },
  methods: {
    getCurrentUserService() {
      this.$store
        .dispatch('service/getCurrentUserService')
        .then((response) => {
          this.userServices = response.data.data
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
    checkCompanyServiceUserService(serviceID) {
      console.log('Hi!')
      let findIndexCompanyService = this.companyServices.findIndex((item) => {
        return item.serviceId == serviceID
      })
      let findIndexUserService = this.userServices.findIndex((item) => {
        return item.serviceId == serviceID
      })
      if (
        this.companyServices[findIndexCompanyService].isActive &&
        this.userServices[findIndexUserService].isActive
      ) {
        return true
      }
      return false
    },
    checkCompanyService() {
      this.$store
        .dispatch('service/getCompanyService')
        .then((response) => {
          this.companyServices = response
          if (
            this.companyServices.length === 2 &&
            this.currentUser.user.role !== 'Owner'
          ) {
            let url = this.companyServices[1].service.domain
            window.location.href = url
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
    updatePassword() {
      this.buttonDisabled = true
      this.$store.dispatch('user/updatePassword', this.password).then(
        async () => {
          this.buttonDisabled = false
          this.isComponentModalActive = false
          this.$buefy.toast.open({
            message: 'Password Successfully Changed !',
            type: 'is-success',
          })
          this.resetForm()
        },
        () => {
          this.buttonDisabled = false
          this.$buefy.toast.open({
            message: 'Password Failed to be Changed !',
            type: 'is-danger',
          })
        }
      )
    },
    updatePasswordModal() {
      this.isComponentModalActive = true
    },

    resetForm() {
      this.password = {
        oldPassword: null,
        newPassword: null,
      }
    },
  },
}
</script>

<style>
.backround {
  background-image: url('/images/login.png');
  background-size: 375px auto;
  height: 300px;
}

.error-message {
  margin-top: -6px;
  color: red;
  font-size: 10px;
}
</style>
